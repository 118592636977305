import Billboard from '@/models/Billboard'
import WebMessage from '@/models/WebMessage'
import router from '@/Routes'

export default [
  {
    key: 'id',
    label: 'id',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'code',
    label: 'Code',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'pin',
    label: 'Pin',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'action',
    label: '',
    sortable: false,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'action_list',
    actions: [
      {
        icon: 'pencil',
        title: 'Edit',
        event: 'edit',
        top_level: true,
        action: (billboard: Billboard) => {
          router.push({
            name: 'billboard',
            params: { id: billboard.id! },
          })
        },
      },
      {
        icon: 'link',
        title: 'Get URL',
        event: 'url',
        top_level: true,
      },
      {
        icon: 'eye',
        title: 'Details',
        event: 'details',
        top_level: false,
        show: false,
        action: (billboard: Billboard) => {
          router.push(`/app/billboard/${billboard.id}?mode=preview`)
        },
      },
      {
        icon: 'key',
        title: 'Generate new Pin',
        event: 'new-pin',
        action: (billboard: Billboard) => {
          WebMessage.confirm(
            'Are you sure you want to generate a new pin? This Action will automatically disconnect active screens!',
            'Reset Pin',
            {
              okTitle: 'Yes',
              cancelTitle: 'No',
            },
          ).then((result: boolean) => {
            if (result) billboard.regenPin()
          })
        },
      },
      {
        icon: 'sync',
        title: 'Force Reload',
        event: 'reload',
        action: (billboard: Billboard) => {
          WebMessage.confirm(
            'Are you sure you want reload the billboard? Please make sure you have access to the billboard in case you need to reenter the PIN!',
            'Reload Billboard',
            {
              okTitle: 'Yes',
              cancelTitle: 'No',
            },
          ).then((result: boolean) => {
            if (result) billboard.reload()
          })
        },
      },
      {
        icon: 'trash',
        title: 'Delete',
        event: 'delete',
        action: (billboard: Billboard) => {
          billboard.delete()
        },
      },
    ],
  },
]
